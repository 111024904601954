export default {
  common: {
    cancel: "キャンセル",
    change: "変更",
    close: "閉じる",
    delete: "削除",
    termsOfUse: "利用規約",
    privacyPolicy: "プライバシーポリシー",
    details: "詳細",
  },
  scenePage: {
    noDescription: "シーンの説明文が書かれていません。",
    recommendedScenes: "おすすめのシーン",
  },
  myPage: {
    noPublishedScene: "シーンがまだありません",
    goStudioAndPublish: "Studio でシーンをつくってみましょう",
    noMylistedScene: "Mylist に追加されたシーンがまだありません",
    mylistShowUp: "追加したらこちらに表示されます",
    noFollowee: "まだだれもフォローしていません",
    followeeShowUp: "フォローするとこちらに表示されます",
  },
}
