import React, { useState, useEffect, } from "react"
import { Link as RouterDomLink, RouteComponentProps, } from "react-router-dom"
import style from "./style.module.scss"
import { login, signup, selectState, deleteBrowingHistory, } from "app/App/redux"
import { ConnectedProps, connect, useSelector, } from "react-redux"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { validator, FieldKey, } from "pages/LoginPage/validator"
import history from "utils/history"
import { tunnelClient, } from "globalInstance"

const mapDispatch = { login, signup, deleteBrowingHistory, }
const connector = connect(null, mapDispatch)
type Props = ConnectedProps<typeof connector> & RouteComponentProps<{location: any}>

const ErrorMessage = {
  USER_NAME: "Username must be alphabets, numbers, \"_\" or \"-\".",
  EMAIL: "Invalid email",
  PASSWORD:
    "Password must be alphabets, numbers or symbols. The length must be 4 or more AND 25 or less.",
}

function LoginPage ({ location, deleteBrowingHistory, signup, login, }: Props) {
  const isSignup = (location.search || "").startsWith("?signup")
  const state = useSelector(selectState)
  const {
    me,
    authErrorMessage,
  } = state

  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorUsername, setErrorUsername] = useState(null as (string | null))
  const [errorEmail, setErrorEmail] = useState(null as (string | null))
  const [errorPassword, setErrorPassword] = useState(null as (string | null))

  useEffect(() => {
    deleteBrowingHistory()
  }, [deleteBrowingHistory])

  useEffect(() => {
    setErrorUsername(null)
    setErrorEmail(null)
    setErrorPassword(null)

    if (isSignup && username && !validator[FieldKey.USERNAME](username)) {
      setErrorUsername(ErrorMessage.USER_NAME)
    }
    if (email && !validator[FieldKey.EMAIL](email)) {
      setErrorEmail(ErrorMessage.EMAIL)
    }
    if (password && !validator[FieldKey.PASSWORD](password)) {
      setErrorPassword(ErrorMessage.PASSWORD)
    }
  }, [username, email, password, isSignup])

  /**
   * SIGN UP押下時の処理
   */
  const onSignupClick = () => {
    if (username && email && password) {
      signup({ username, email, password, })
    }
  }

  /**
   * LOGIN押下時の処理
   */
  const onLoginClick = () => {
    if (email && password) {
      login({ email, password, })
    }
  }

  /**
   * Terms of Use押下時の処理
   */
  const onTermsOfUseClick = async () => {
    await tunnelClient.openExternalPage("https://styly.cc/styly-terms-of-use/")
  }

  /**
   * Privacy Policy押下時の処理
   */
  const onPrivacyPolicyClick = async () => {
    await tunnelClient.openExternalPage("https://styly.cc/styly-service-privacy-policy/")
  }

  const hasError = () => (errorUsername || errorEmail || errorPassword)

  useEffect(() => {
    if (me) {
      // ログイン後はTOPページへリダイレクト
      history.push("/")
    }
  }, [me])

  return (
    <div className={style.frame}>
      <div className={style.wrapper}>
        <div className={style.header}>
          {(isSignup) ? "SIGN UP" : "LOGIN"}
        </div>
        {isSignup && (<TextField
          error={!!errorUsername}
          helperText={errorUsername}
          variant='outlined'
          fullWidth={true}
          label='User Name'
          className={style.textfield}
          onChange={(e) => setUsername(e.target.value)}
        />)}
        <TextField
          error={!!errorEmail}
          helperText={errorEmail}
          variant='outlined'
          fullWidth={true}
          label='Email address'
          className={style.textfield}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          error={!!errorPassword}
          helperText={errorPassword}
          variant='outlined'
          fullWidth={true}
          label='Password'
          type='password'
          className={style.textfield}
          onChange={(e) => setPassword(e.target.value)}
        />
        {authErrorMessage && (
          <div className={style.errorContainer}>{authErrorMessage}</div>
        )}
        {
          (isSignup) ? (
            <Button
              disabled={!(username && email && password && !hasError())}
              variant='contained'
              size='large'
              fullWidth={true}
              onClick={onSignupClick}
            >
              <span>SIGN UP</span>
            </Button>
          ) : (
            <Button
              disabled={!(email && password && !hasError())}
              variant='contained'
              size='large'
              fullWidth={true}
              onClick={onLoginClick}
            >
              <span>LOGIN</span>
            </Button>
          )
        }
        {(isSignup) ? (<div className={style.message}>
          Already have acount?
          <RouterDomLink to={"/login"}>
            <span className={style.link}>Switch to Login</span>
          </RouterDomLink>
        </div>) : (<div className={style.message}>
          You do not have account?
          <RouterDomLink to={"/login?signup"}>
            <span className={style.link}>Switch to Signup</span>
          </RouterDomLink>
        </div>)}
        <div className={style.caption}>
          <span className={style.link} onClick={onTermsOfUseClick}>
            Terms of Use
          </span>
          <span className={style.separater}>/</span>
          <span className={style.link} onClick={onPrivacyPolicyClick}>
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  )
}

export default connector(LoginPage)
