export default {
  common: {
    cancel: "Cancel",
    change: "Change",
    close: "Close",
    delete: "Delete",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    details: "Details",
  },
  scenePage: {
    noDescription: "This scene has no description.",
    recommendedScenes: "Recommended scenes for you",
  },
  myPage: {
    noPublishedScene: "You don't have any scenes yet.",
    goStudioAndPublish: "Go to Studio and create scenes.",
    noMylistedScene: "You haven't added any scenes to Mylist yet.",
    mylistShowUp: "When you do it, it'll shows up here.",
    noFollowee: "You aren't following anyones yet.",
    followeeShowUp: "When you do it, they'll be listed.",
  },
}
